<template>
  <div v-swiper:CarSlider="settings">
    <div class="swiper-wrapper text-center">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'CarSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        autoplay: { delay: 3000 },
        direction: "horizontal",
        loop: 1,
        spaceBetween: 0,
        breakpoints: {
          480: {
            slidesPerView: 2,
            spaceBetween: 50
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 50
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 80
          }
        }
      }
    }
  }
}
</script>
