import Vue from 'vue'
window.Vue = Vue
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
import axios from 'axios'

import { extend } from 'vee-validate'
import { required, email, regex } from 'vee-validate/dist/rules'
import ContactForm from './components/ContactForm'
import CookieConsent from './components/CookieConsent'
import AppFixedHeader from './components/AppFixedHeader'
import TestimonialSlider from './components/TestimonialSlider'
import CarSlider from './components/CarSlider'
import AboutSlider from './components/AboutSlider'
import HeroSlider from './components/HeroSlider'
import AppNavBar from './components/AppNavBar'
import Accordion from './components/Accordion'
import GallerySlider from './components/GallerySlider'

Vue.component('ContactForm', ContactForm)
Vue.component('CookieConsent', CookieConsent)
Vue.component('TestimonialSlider', TestimonialSlider)
Vue.component('CarSlider', CarSlider)
Vue.component('AboutSlider', AboutSlider)
Vue.component('HeroSlider', HeroSlider)
Vue.component('AppFixedHeader', AppFixedHeader)
Vue.component('AppNavBar', AppNavBar)
Vue.component('Accordion', Accordion)
Vue.component('GallerySlider', GallerySlider)

const files = require.context('./components/icons/', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


extend('required', {
  ...required,
  message: 'The {_field_} is required'
})

extend('email', email)
extend('regex', regex)

extend('min', {
  validate (value, args) {
    return value.length >= args.length
  },
  params: ['length'],
  message: 'The {_field_} field must have at least {length} characters'
})

extend('max', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field may not be greater than {length} characters'
})

extend('length', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field can have only {length} of checked items'
})

Vue.prototype.$axios = axios
Vue.config.productionTip = false

new Vue({
    el: '#app'
});

(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NQ429CX');
