<template>
  <div v-swiper:HeroSlider="settings" style="overflow:inherit">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="xl:hidden cards-pagination swiper-pagination text-center"></div>
  </div>
</template>
<script>
export default {
  name: 'HeroSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        spaceBetween: 0,
        direction: "horizontal",
        loop: false,
        pagination: {
          clickable: true,
          el: '.cards-pagination'
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 32,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 10,
          }
        }
      }
    }
  }
}
</script>
