<template>
  <div
    v-if="isOpen"
    class="fixed left-0 right-0 bottom-0 z-50 p-4"
  >
    <div class="md:flex justify-between items-center max-w-screen-lg mx-auto bg-white p-4 rounded-lg shadow-lg">
      <p class="text-sm md:pr-8">
        We use cookies and other tracking technologies to improve your browsing experience on our website. By using our website, you consent to our use of cookies and other tracking technologies.
      </p>
      <div class="md:flex">
        <button
          class="button solid black mt-4 md:mt-0"
          type="button"
          aria-label="Close"
          @click="accept"
        >
          Got It
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { get, set } from 'tiny-cookie'
import { supportsLocalStorage } from '../mixins/Functions'
export default {
  name: 'CookieConsent',
  data () {
    return {
      isOpen: false,
      storageName: 'cookieconsent'
    }
  },
  mounted () {
    if (!this.getVisited()) {
      this.isOpen = true
    }
  },
  methods: {
    getVisited () {
      if (supportsLocalStorage()) {
        return localStorage.getItem(this.storageName)
      } else {
        return get(this.storageName)
      }
    },
    setAccepted () {
      if (supportsLocalStorage()) {
        localStorage.setItem(this.storageName, 'accepted')
      } else {
        set(this.storageName, 'accepted')
      }
    },
    setDeclined () {
      if (supportsLocalStorage()) {
        localStorage.setItem(this.storageName, 'declined')
      } else {
        set(this.storageName, 'declined')
      }
    },
    accept () {
      this.setAccepted()
      this.isOpen = false
    },
    decline () {
      this.setDeclined()
      this.isOpen = false
    }
  }
}
</script>
