<template>
  <div class="fixed-menu sticky -top-px z-30 transition-colors duration-200 bg-white">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'AppFixedHeader',
  data () {
    return {
      observer: null
    }
  },
  created () {
    this.observer = new IntersectionObserver(
      this.onElementObserved,
      { root: this.$el, threshold: [1] }
    )
  },
  mounted () {
    this.observer.observe(this.$el)
  },
  beforeDestroy () {
    this.observer.disconnect()
  },
  methods: {
    onElementObserved ([e]) {
      e.target.classList.toggle('shadow-lg', e.intersectionRatio < 1)
    }
  }
}
</script>
