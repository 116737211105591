<template>
  <div v-swiper:TestimonialSlider="settings" style="overflow:inherit">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="testimonial-pagination swiper-pagination text-center"></div>
  </div>
</template>
<script>
export default {
  name: 'TestimonialSlider',
  data () {
    return {
      settings: {
        loop: true,
        pagination: {
          clickable: true,
          el: '.testimonial-pagination'
        },
        spaceBetween: 1000,
        slidesPerView: 1
      }
    }
  }
}
</script>
